<template>
  <div style="margin: 20px" class="">
    <div class="merch-card scorr-roll">
      <div class="head" style="margin-top: -20px" v-if="state">
        <div class="head1">审核结果</div>
        <div class="head2"></div>
        <div>
          <div class="consequence">
            <div>
              <i
                class="el-icon-circle-close"
                v-if="data.log_info.audit == 2"
              ></i>
              <i class="el-icon-success" v-if="data.log_info.audit == 1"></i>
              <i class="el-icon-question" v-if="data.log_info.audit == 0"></i>
              <div>{{ data.log_info.audit | audit }}</div>
            </div>
            <div class="consequence-mess">
              <div>审核人：{{ data.log_info.operator }}</div>
              <div>{{ data.log_info.create_time }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="head" :style="state ? '' : 'margin-top: -20px'">
        <div class="head1">企业基础信息</div>
        <div class="head2"></div>
        <div class="head3" style="font-size: 14px">修改后</div>
        <div style="line-height: 25px">
          <el-row :gutter="20">
            <el-col :span="8"
              ><div>单位名称:{{ data.log_info.enterprise }}</div></el-col
            >
            <el-col :span="8"
              ><div>单位简称：{{ data.log_info.shop_name }}</div></el-col
            >
            <el-col :span="8"
              ><div>法人：{{ data.log_info.legal_entity }}</div></el-col
            >
            <el-col :span="8"
              ><div>
                企业注册地：{{ data.log_info.province_name }}-{{
                  data.log_info.city_name
                }}-{{ data.log_info.district_name }}
              </div></el-col
            >
            <el-col :span="8"
              ><div>详细地址：{{ data.log_info.address }}</div></el-col
            >
            <el-col :span="8"
              ><div>会员类型：{{ data.log_info.user_type_name }}</div></el-col
            >
          </el-row>
        </div>
        <div class="head2" style="margin-top: 10px"></div>
        <div class="head3" style="font-size: 14px">修改前</div>
        <div style="line-height: 25px" v-if="data.snapshot">
          <el-row :gutter="20">
            <el-col :span="8"
              ><div>单位名称:{{ data.snapshot.enterprise }}</div></el-col
            >
            <el-col :span="8"
              ><div>单位简称：{{ data.snapshot.shop_name }}</div></el-col
            >
            <el-col :span="8"
              ><div>法人：{{ data.snapshot.legal_entity }}</div></el-col
            >
            <el-col :span="8"
              ><div>
                企业注册地：{{ data.snapshot.province_name }}-{{
                  data.snapshot.city_name
                }}-{{ data.snapshot.district_name }}
              </div></el-col
            >
            <el-col :span="8"
              ><div>详细地址：{{ data.snapshot.address }}</div></el-col
            >
            <el-col :span="8"
              ><div>会员类型：{{ data.snapshot.user_type_name }}</div></el-col
            >
          </el-row>
        </div>
        <div style="line-height: 25px" v-if="data.log_info.snapshot">
          <el-row :gutter="20">
            <el-col :span="8"
              ><div>
                单位名称:{{ data.log_info.snapshot.enterprise }}
              </div></el-col
            >
            <el-col :span="8"
              ><div>
                单位简称：{{ data.log_info.snapshot.shopname }}
              </div></el-col
            >
            <el-col :span="8"
              ><div>法人：{{ data.log_info.snapshot.legalentity }}</div></el-col
            >
            <el-col :span="8"
              ><div>
                企业注册地：{{ data.log_info.snapshot.province_name }}-{{
                  data.log_info.snapshot.city_name
                }}-{{ data.log_info.snapshot.district_name }}
              </div></el-col
            >
            <el-col :span="8"
              ><div>详细地址：{{ data.log_info.snapshot.address }}</div></el-col
            >
            <el-col :span="8"
              ><div>
                会员类型：{{ data.log_info.snapshot.user_type_name }}
              </div></el-col
            >
          </el-row>
        </div>
      </div>
      <div class="head rela">
        <div class="head1">资质证照信息</div>
        <div class="abso updown" @click="todown">下载所有证件</div>
        <div class="head2"></div>
        <div v-if="data.qualify != undefined">
          <el-row :gutter="20">
            <el-col
              :span="12"
              v-for="(item, index) in data.qualify"
              :key="index"
            >
              <div v-if="item.detail">
                <div class="fx">
                  <div class="fx-name">{{ item.item_name }}</div>
                  <div style="margin-left: 10px; margin-bottom: 10px">
                    有效期：{{
                      item.effective_end_time == ""
                        ? "永久有效"
                        : item.effective_end_time
                    }}
                    <div>
                      <el-image
                        style="margin-top: 10px"
                        :preview-src-list="[item.detail[0].img_url]"
                        class="image"
                        :src="item.detail[0].img_url"
                        fit="scale-down"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="head">
        <div class="head1">经营范围</div>
        <div class="head2"></div>
        <div style="line-height: 25px">
          <el-row :gutter="20">
            <el-col :span="8" v-for="(v, i) in data.scope" :key="i"
              ><div v-if="v.status==1">
                {{ v.name }}
                <span style="margin-left: 10px"
                  >有效期：{{
                    v.expiration_time == "" ? "永久有效" : v.expiration_time
                  }}</span
                >
              </div></el-col
            >
          </el-row>
        </div>
      </div>
      <div class="btn-box">
        <div class="btn-2" @click="$router.push({ path: '/userAudit' })">
          返回
        </div>
        <div class="btn-3" @click="opstaed" v-if="!state">审核驳回</div>
        <div class="btn-1" @click="onstae" v-if="!state">审核通过</div>
      </div>
    </div>

    <div style="height: 40px"></div>
    <!-- 审核驳回提醒弹窗 -->
    <el-dialog title="审核驳回提醒" :visible.sync="dialogVisible" width="400px">
      <div class="tsed">
        确定驳回当前会员的修改资料审核？如果当前会员信息不符合平台要求，请在驳回原因中注明
      </div>
      <div class="fx">
        <span style="flex: 2">驳回原因：</span>
        <el-input
          :autosize="{ minRows: 2, maxRows: 3 }"
          maxlength="200"
          style="flex: 7"
          type="textarea"
          placeholder="请输入内容"
          v-model="textarea"
          show-word-limit
        >
        </el-input>
      </div>
      <div slot="footer" class="text-center">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="assdistribued">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { downLoadZip } from "@/utils/zipdownload";
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("Audit"); //vuex公共库
// import { userInfo, reviewUserInfo } from "@/api/userqualiy.js";
export default {
  name: "compser",
  props: {
    state: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      data: {
        log_info: {
          audit: "",
        },
      },
      dialogVisible: false, //审核驳回弹窗
      textarea: "", //驳回原因
    };
  },
  filters: {
    audit(v) {
      switch (v) {
        case 0:
          return "待审核";
        case 1:
          return "审核成功";
        case 2:
          return "审核失败";
      }
    },
  },
  created() {
    this.getuserInfo();
  },
  methods: {
    ...mapActions([
      "getmemberAuditqualifyDown",
      "getmemberAuditUserInfo",
      "postmemberAuditReviewUserInfo",
    ]),
    // 获取信息
    async getuserInfo() {
      let res = await this.getmemberAuditUserInfo({
        log_id: this.$route.query.id,
        review_id: this.$route.query.id_row,
      });
      this.data = res.data;
    },
    //通过
    onstae() {
      this.$confirm(
        "确定通过当前会员的修改资料审核？请您仔细核对会员修改前后信息，以保证会员信息符合要求且填写无误。",
        "审核通过提醒",
        {
          confirmButtonText: "审核通过",
          cancelButtonText: "取消",
        }
      )
        .then(() => {
          this.postmemberAuditReviewUserInfo({
            log_id: this.$route.query.id,
            audit: 1,
          })
            .then((res) => {
              if (res.code == 200) {
                this.$message.success("审核通过");
                this.$router.push({ path: "/userAudit" });
              }
            })
            .catch((err) => {
              if (err.code == 90000) {
                this.$alert(
                  "您审核的当前会员已重新提交修改资质申请，点击确定返回列表，请以新的信息为准进行审核。",
                  "会员资质变动提醒",
                  {
                    confirmButtonText: "确定",
                    callback: (action) => {
                      this.$router.go(-1);
                    },
                  }
                );
              }
            });
        })
        .catch((err) => {});
    },
    //驳回
    opstaed() {
      this.dialogVisible = true;
    },
    //审核驳回
    assdistribued() {
      this.postmemberAuditReviewUserInfo({
        log_id: this.$route.query.id,
        audit: 2,
        reason: this.textarea,
      })
        .then((res) => {
          if (res.code == 200) {
            this.dialogVisible = false;
            this.$message({
              type: "success",
              message: "驳回成功!",
            });
            this.$router.push({
              path: "/userAudit",
            });
          }
        })
        .catch((err) => {
          if (err.code == 90000) {
            this.$alert(
              "您审核的当前会员已重新提交修改资质申请，点击确定返回列表，请以新的信息为准进行审核。",
              "会员资质变动提醒",
              {
                confirmButtonText: "确定",
                callback: (action) => {
                  this.$router.go(-1);
                },
              }
            );
          }
        });
    },
    //  下载
    async todown() {
      // downLoadZip(
      //   "/userQualification/memberAudit/qualifyDown?user_id=" +
      //     this.data.log_info.user_id,
      //   "jpeg",
      //   "未分类品种列表"
      // );

      let data = await this.getmemberAuditqualifyDown({
        user_id: this.data.log_info.user_id,
      });
      if (data.code == 200) {
        if (data.data.url == "") {
          this.$message({
            message: "暂无资质可下载",
            type: "warning",
          });
        } else {
          window.open(data.data, "");
          this.$message({
            message: data.message,
            type: "success",
          });
        }
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.merch-card {
  width: 100%;
  height: 100%;
  min-height: 600px;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  background: #ffffff;
}
.consequence {
  display: flex;
  font-weight: bold;
  color: #000;
  margin-left: 50px;
  .consequence-mess {
    font-weight: normal;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 14px;
  }
  i {
    font-size: 60px;
    margin-bottom: 10px;
    margin-right: 50px;
  }
  .el-icon-circle-close {
    color: #e63e3f;
  }
}
.fx {
  display: flex;
  .fx-name {
    width: 80px;
    text-align: end;
  }
}
.head {
  font-size: 14px;
  color: rgb(127, 127, 127);
  margin-top: 40px;
  .head1 {
    color: #000;
    font-weight: bold;
    font-size: 15px;
    margin: 10px 0px;
  }
  .head3 {
    color: #000;
    font-weight: bold;
    font-size: 15px;
    margin: 10px 0px;
  }
  .head1::before {
    width: 4px;
    height: 16px;
    background-color: #06b7ae;
    border-radius: 10px;
    display: inline-block;
    content: "";
    margin-right: 10px;
  }
  .head2 {
    border-top: 1px dashed rgb(192, 190, 190);
    margin-bottom: 20px;
  }
}
.updown {
  right: 1%;
  top: 0px;
  cursor: pointer;
  color: #06b7ae;
}
.image {
  width: 140px;
  height: 140px;
  margin-right: 10px;
}
.tsed {
  margin-bottom: 20px;
  line-height: 20px;
}
.el-icon-error {
  color: #f00;
}
.el-icon-success {
  color: #70b603;
}
.el-icon-question {
  color: #e8dad1;
}
.btn-box{
  cursor: default;
  div{
    cursor: pointer;
  }

}
</style>
